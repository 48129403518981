import { CardActions, CardContent } from '@material-ui/core'
import { getDefaultOptions } from 'API/auth'
import { getForum, getSubmissionForum } from 'API/getForum'
import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import axios from 'axios'
import { CustomModal } from 'components/common/useModal'
import { Form } from 'components/Form/Form'
import { PageProps } from 'gatsby'
import { useGetCourseDetails } from 'hooks/useGetCourseDetails'
import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { NonUndefined, PromiseType } from 'utility-types'
import BootstrapSwitchButton from 'components/switch/switch'
const ReactQuill = typeof window !== 'undefined' ? require('react-quill') : () => <></>
import 'quill/dist/quill.snow.css'
import 'styles/index.scss'

export type AssetType = {
	token?: string
	name: string
	description: string
	doc: boolean
}

const ForumView: React.FC<PageProps> = ({ params: { id, forum_id } }) => {
	const { ShowDetails, viewAs } = useGetCourseDetails(id)
	const [asset, setAsset] = useState<PromiseType<ReturnType<typeof getForum>>>()
	const [update, setUpdate] = useState(0)
	const [assetSubmission, setAssetSubmission] = useState<NonUndefined<PromiseType<NonUndefined<ReturnType<typeof getSubmissionForum>>>>>([])
	useEffect(() => {
		const run = async () => {
			const asset = await getForum(forum_id, viewAs)

			if (asset) {
				setAsset(asset)
				console.log(asset)
			}
		}
		run()
	}, [forum_id, viewAs, update])

	const [disabled, setDisabled] = useState(false)

	useEffect(() => {
		const run = async () => {
			if (viewAs === 'Teacher') {
				const submission = await getSubmissionForum(forum_id, id)
				if (submission) {
					setAssetSubmission(submission)
					console.log(submission)
				}
			}
		}
		run()
	}, [forum_id, viewAs])
	const [toggle, settoggle] = useState(false)
	return (
		<>
			<CustomModal header='Forum'>
				{ShowDetails}

				<BootstrapSwitchButton
					width={200}
					checked={toggle}
					onlabel='Hide List of Students'
					offlabel='Show List of Students'
					offstyle='primary'
					onChange={(checked: boolean) => {
						settoggle(checked)
					}}
				/>
				{toggle && asset && viewAs === 'Teacher' && (
					<div>
						<div className='row mt-5'>
							<div className='col-6'>
								<span>
									<h4>Viewed Student</h4>
								</span>
								<table className='table'>
									<thead>
										<tr>
											<th scope='col'>Index</th>
											<th scope='col'>Name</th>
											<th scope='col'>UserName</th>
											<th scope='col'>Time</th>
										</tr>
									</thead>
									<tbody>
										{assetSubmission
											.filter((assetSubmission) => assetSubmission.isOpened === true)
											.map((elem, index) => {
												return (
													<>
														<tr>
															<th scope='row'>{index + 1}</th>
															<td>
																{elem.first_name} {elem.last_name}
															</td>
															<td>{elem.username}</td>
															<td>{new Date(elem.last_open_date).toLocaleString()}</td>
														</tr>
													</>
												)
											})}
									</tbody>
								</table>
							</div>
							<div className='col-6'>
								<span>
									<h4>Not Viewed</h4>
								</span>
								<table className='table'>
									<thead>
										<tr>
											<th scope='col'>Index</th>
											<th scope='col'>Name</th>
											<th scope='col'>Username</th>
										</tr>
									</thead>
									{assetSubmission
										.filter((assetSubmission) => assetSubmission.isOpened == false)
										.map((elem, index) => {
											return (
												<>
													<tr>
														<th scope='row'>{index + 1}</th>
														<td>
															{elem.first_name} {elem.last_name}
														</td>
														<td>{elem.username}</td>
													</tr>
												</>
											)
										})}
								</table>
							</div>
						</div>
					</div>
				)}
				{asset && (
					<>
						<div className='base mb-2'>
							<h4 className='pt-2'>Title: {asset.name}</h4>
							<ReactQuill
								theme='snow'
								value={asset.description}
								readOnly={true}
								modules={{
									toolbar: false,
								}}
							/>
						</div>
						<div className='add-parent'>
							<Form
								text='Add Thread'
								action={async ({ state }) => {
									if (disabled) {
										return
									}
									try {
										setDisabled(true)

										await handleApi<{ uid: string }>(
											axios.post(
												getUrl('forum/add_parent_comment'),
												{
													comment: {
														...state,
													},
													forum_id,
												},
												getDefaultOptions()
											)
										)
										setUpdate((update) => update + 1)
									} catch (err) {
										console.error(err)
									}
									setDisabled(false)
								}}
								fields={[
									{
										type: 'input',
										label: 'Comment',
										name: 'data',
									},
								]}
							></Form>
							<div className='accordion' id='accordionExample'>
								<Card>
									{asset.parents.reverse().map((elem) => (
										<>
											{/* <h6>{elem.data}</h6> */}
											<div className='card'>
												<div className='card-header'>
													<h5>{elem.data}</h5>
												</div>
												<div>
													<div className='card-body'>
														<CardContent>
															{elem.children.map((child) => {
																return (
																	<>
																		<Row className='border my-3'>
																			{/* <Col md={1}></Col> */}
																			<Col className='p-2' md={2}>
																				<h6>
																					{child.author.first_name} {child.author.last_name} :
																				</h6>
																				<p className='time-change text-primary'>
																					{new Date(child.createdAt).toLocaleTimeString()}
																				</p>
																				<p className='time-change1 text-primary'>
																					{new Date(child.createdAt).toLocaleDateString()}
																				</p>
																			</Col>
																			<Col className='p-2' md={10}>
																				<p>{child.data}</p>

																				{console.log(child)}
																				{console.log(child.author.teacher)}
																			</Col>
																			{/* <Col md={1}></Col> */}
																		</Row>
																	</>
																)
															})}
														</CardContent>

														<Row>
															<Col md={1}></Col>
															<Col md={11}>
																<Form
																	text='Add Reply'
																	action={async ({ state }) => {
																		if (disabled) {
																			return
																		}
																		try {
																			setDisabled(true)

																			await handleApi<{ uid: string }>(
																				axios.post(
																					getUrl('forum/add_child_comment'),
																					{
																						comment: {
																							...state,
																							parent: elem._id,
																						},
																						forum_id,
																					},
																					getDefaultOptions()
																				)
																			)
																			setUpdate((update) => update + 1)
																		} catch (err) {
																			setDisabled(false)
																		}
																		setDisabled(false)
																	}}
																	fields={[
																		{
																			type: 'input',
																			label: 'Comment',
																			name: 'data',
																		},
																	]}
																></Form>
															</Col>
														</Row>
													</div>

													<hr />
												</div>
											</div>
										</>
									))}
								</Card>
							</div>
						</div>
					</>
				)}
			</CustomModal>
		</>
	)
}

export default ForumView
